import { fetchPost, downLoadFile, downLoadFiles } from './request.js'

// 首页获取新闻列表
export const HomeNewsList = (params) => {
  return fetchPost('/trade/cms/list', params)
}
// 首页获取轮播图
export const HomeImages = (params) => {
  return fetchPost('/cms/tradeCarouselImg/carouselImgList', params)
}
// 一级栏目导航
export const GetHeaderNavList = (params) => {
  return fetchPost('/cms/cmsType/getAll', params)
}
// 二级栏目导航
export const GetHeaderNavChildrenList = (params) => {
  return fetchPost('/cms/cmsType/getTreeById', params)
}
// // 独立栏目栏目
export const GetColumnIndependentDetails = (params) => {
  return fetchPost('/cms//cmsType/getInfoById', params)
}
// // 栏目对应新闻
export const GetHeaderNavColumnNewsList = (params) => {
  return fetchPost('/cms/cms/pages', params)
}
// // 新闻详情
export const GetNewsDetails = (params) => {
  return fetchPost('/cms/cms/get', params)
}
// 文件下载 (新)
export const uploadFileDownload = (params) => {
  return downLoadFiles('/system/files/noAccessDownloadFile', params)
}
// export const GetHeaderNavColumnList = (params) => {
//   return fetchPost('/cms/cmsType/getTreeById', params)
// }
// 登陆
export const LoginAdminLogin = (params) => {
  return fetchPost('/system/login', params)
}
// 退出登录
export const LoginAdminLogout = (params) => {
  return fetchPost('/system/w/logout', params)
}
// 图片验证----获取验证码
export const GetSliderCode = (params) => {
  return fetchPost('/system/sliderCode/create', params)
}
// 图片验证----获取验证码
export const CheckSliderCode = (params) => {
  return fetchPost('/system/sliderCode/valid', params)
}
// 用户目录树 back_manager 后台 memb_center前台
export const myMenuTree = (params) => {
  return fetchPost('/system/w/user/viewMenu/list', params)
}
// 文件上传 downLoadFile
export const Uploadimg = (params) => {
  return fetchPost('/system/w/uploadFile', params)
}
// 后台轮播图 列表
export const GetCarouselList = (params) => {
  return fetchPost('/cms/i/a/carouselImg/page', params)
}
// 后台轮播图 新增
export const AddCarousel = (params) => {
  return fetchPost('/cms/i/a/carouselImg/insert', params)
}
// 后台轮播图 修改
export const UpdateCarousel = (params) => {
  return fetchPost('/cms/i/a/carouselImg/update', params)
}
// 后台轮播图 删除
export const DeleteCarousel = (params) => {
  return fetchPost('/cms/i/a/carouselImg/delete', params)
}
// 栏目管理---- 列表
export const GetColumnList = (params) => {
  return fetchPost('/cms/i/a/cmsType/tree', params)
}
// 栏目管理---- 增加栏目
export const AddColumnList = (params) => {
  return fetchPost('/cms/i/a/cmsType/addCmsType', params)
}
// 栏目管理---- 获取栏目详情
export const GetColumnListById = (params) => {
  return fetchPost('/cms/i/a/cmsType/getInfoById', params)
}
// 栏目管理---- 修改栏目
export const UndateColumnList = (params) => {
  return fetchPost('/cms/i/a/cmsType/update', params)
}
// 栏目管理---- 删除栏目
export const ColumnDelete = (params) => {
  return fetchPost('/cms/i/a/cmsType/delete', params)
}
// 文章--- 列表
export const newsList = (params) => {
  return fetchPost('/cms/i/a/cms/page', params)
}
// 文章--- 列表状态修改
export const newsStatustUpdate = (params) => {
  return fetchPost('/cms/i/a/cms/upState', params)
}
// 文章--- 发布
export const newsAdd = (params) => {
  return fetchPost('/cms/i/a/cms/add', params)
}
// 文章--- 获取文章详情
export const GetNewsDetail = (params) => {
  return fetchPost('/cms/i/a/cms/get', params)
}
// 文章--- 文章修改
export const newsUpdate = (params) => {
  return fetchPost('/cms/i/a/cms/update', params)
}
// 文章--- 文章删除
export const newsDelete = (params) => {
  return fetchPost('/cms/i/a/cms/del', params)
}
/* 文件上传 */
// 文件上传
export const ServicesUploadFile = (params) => {
  return fetchPost('/system/w/uploadFile', params)
}
// 下载附件
export const ServicesDownfu = (params) => {
  return downLoadFile('/system/w/downloadByPath', params)
}
// 文件删除
export const ServicesDeleteFile = (params) => {
  return fetchPost('/system/w/deleteFile', params)
}
// 修改密码
export const EditPwd = (params) => {
  return fetchPost('/system/w/user/pwd/update2', params)
}
