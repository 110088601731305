<template>
  <div class="lht-news">
    <div class="wrapper">
      <!-- <div class="lht-news-crumbs clearfix">
      </div> -->
      <div class="lht-news-container clearfix">
        <div class="lht-news-container-main pull-left">
          <ul class="lht-news-container-main-list" v-if="newsList.length > 0">
            <li class="lht-news-container-main-item clearfix" v-for="(item, index) in newsList" :key="index">
              <div class="lht-news-container-main-item-text pull-left" @click="handleDetails(item)">{{item.title}}</div>
              <div class="lht-news-container-main-item-date pull-right">{{item.publishTime}}</div>
            </li>
          </ul>
          <div class="lht-news-container-main-list-no" v-else>
            <img style="width: 400px; height: 400px; display: block; margin: 0 auto;" src="../assets/image/search_no.jpg" alt="">
          </div>
        </div>
      </div>
      <el-pagination v-if="newsParams.total > 20"
        background
        :page-size="newsParams.pageSize"
        layout="prev, pager, next"
        @current-change="handleCurrentChange"
        :current-page.sync="newsParams.pageNo"
        :total="newsParams.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { GetHeaderNavColumnNewsList } from '@/api/http'

export default {
  data () {
    return {
      height: 500,
      newsList: [], // 新闻列表
      newsParams: {
        pageNo: 1,
        pageSize: 20,
        total: 0
      }
    }
  },
  watch: {
    '$route.query.content': {
      handler (newValue, oldValue) {
        // console.log('新-----', newValue)
        // console.log('旧-----', oldValue)
        this.newsParams.pageNo = 1
        this.getNewList()
      }
    }
  },
  created () {
    this.getNewList()
  },
  methods: {
    // 跳转新闻详情
    handleDetails (item) {
      if (item.url) {
        window.open(item.url)
      } else {
        const { href } = this.$router.resolve({
          path: '/news/details',
          query: { id: item.id }
        })
        window.open(href, '_blank')
      }
    },
    // 新闻列表
    getNewList () {
      const params = { cmsType: '', keyword: this.$route.query.content, pageNo: this.newsParams.pageNo, pageSize: this.newsParams.pageSize }
      GetHeaderNavColumnNewsList(params).then(res => {
        if (res.code !== '000') return this.$message({ type: 'error', message: res.msg })
        this.newsParams.total = res.result.total
        this.newsList = res.result.records
      }).catch((err) => {
        console.log(err)
      })
    },
    handleCurrentChange (val) {
      this.newsParams.pageNo = val
      this.getNewList()
    }
  }
}
</script>

<style lang="less" scoped>
.lht-news {
  min-height: calc(100vh - 400px);
  padding-bottom: 100px;
}
.lht-news-crumbs {
  color: #999999;
  padding: 30px 0;
  font-size: 14px;
  line-height: 1;
  .lht-news-crumbs-item:last-child {
    .span {
      color: #FF9852;
    }
  }
}
.lht-news-container {
  .lht-news-container-aside {
    width: 300px;
    .title {
      position: relative;
      .text {
        width: 300px;
        height: 60px;
        line-height: 60px;
        padding: 0 20px;
        font-size: 26px;
        color: #fff;
        position: absolute;
        left: 0;
        top: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .lht-news-container-menu-submenu {
      cursor: pointer;
      height: 50px;
      line-height: 50px;
      padding: 0 40px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      background: -moz-linear-gradient(360deg, #F0F5FF 0%, #F6F8FF 100%);
      background: -webkit-linear-gradient(360deg, #F0F5FF 0%, #F6F8FF 100%);
      background: -o-linear-gradient(360deg, #F0F5FF 0%, #F6F8FF 100%);
      background: -ms-linear-gradient(360deg, #F0F5FF 0%, #F6F8FF 100%);
      background: linear-gradient(360deg, #F0F5FF 0%, #F6F8FF 100%);
      position: relative;
    }
    .lht-news-container-menu-submenu:before {
      content: "";
      position: absolute;
      left: 24px;
      top: 22px;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #D8D8D8;
    }
  }
  .lht-news-container-main {
    width: 100%;
    .lht-news-container-main-list {
      padding: 50px 10px;
      .lht-news-container-main-item {
        line-height: 40px;
        padding: 0 4px 0 22px;
        border-bottom: 1px dashed #e9e9e9;
        position: relative;
        color: #474747;
        font-size: 14px;
        .lht-news-container-main-item-text {
          width: 700px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        .lht-news-container-main-item-date {
          color: #999;
        }
      }
      .lht-news-container-main-item:before {
        content: "";
        position: absolute;
        left: 8px;
        top: 17px;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: #D8D8D8;
      }
    }
  }
}
.el-pagination {
  text-align: right;
  margin-top: 40px;
}
</style>
